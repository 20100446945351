import {FC, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import WebApp from "@twa-dev/sdk";
import toast from "react-hot-toast";
import {useAliveController} from "react-activation";


const BackButton: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const aliveController = useAliveController();
    const handler = () => {
        WebApp.HapticFeedback.impactOccurred("soft")
        let needNavBack: boolean = true;
        if (location.pathname === "/TeacherCreator" || location.pathname === "/TeacherEdit") {
            needNavBack = false;
            WebApp.showConfirm("确定返回吗？\n已填写的部分不会被保存！", (b) => {
                if (b) {
                    navigate(-1)
                }
            })
        }
        if (location.pathname === "/TeacherSelector" || location.pathname === "/ErrorManagement" || location.pathname === "/ReportManagement") {
            aliveController.dropScope(location.pathname.replace("/", "")).then(r => {
                if (!r) {
                    toast.error("卸载" + location.pathname.replace("/", "") + "缓存时出错\n请重启小程序....")
                }
            })
        }
        if(needNavBack){
            navigate(-1)
        }
    }
    useEffect(() => {
        if (location.pathname === "/Main" || location.pathname === "/") {
            WebApp.BackButton.isVisible = false
        } else {
            WebApp.BackButton.isVisible = true
        }
        WebApp.BackButton.offClick(handler);

        // 绑定回调
        WebApp.BackButton.onClick(handler);

        return () => {
            // 卸载时清理回调
            WebApp.BackButton.offClick(handler);
        };
    }, [location.pathname]); // 路由变化时重新绑定
    return null
}

export default BackButton;