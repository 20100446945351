import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import toast from "react-hot-toast";


const service: AxiosInstance = axios.create({
    timeout: 40000,
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
});

service.interceptors.request.use(
    (config: AxiosRequestConfig | any) => {
        config.method = "POST"
        config.headers = {
            ...config.headers,
            'X-Auth-Token': sessionStorage.getItem('X-Auth-Token') as string || ''
        }
        return config;
    },
    (error: AxiosError) => {

        toast.error(error.message)
        return Promise.reject(error.message);
    }
);

service.interceptors.response.use(
    (response: AxiosResponse<ResponseResult> | any) => {
        if (response.status === 200) {
            if (response.data.code !== 20000) {
                handlerError(response.data);
                return new Promise(() => {
                });
            }
            return response;
        } else {
            return Promise.reject();
        }
    },
    (error: AxiosError) => {
        toast(error.message)
        return Promise.reject(error.message);
    }
);
const handlerError = (err: ResponseResult) => {
    toast.error(err.message);
    if (err.code !== 20002) {
        setTimeout(()=>{
            window.location.replace("/");
        },1000)
    }
}

export default async function request<R>(options: AxiosRequestConfig<any>): Promise<AxiosResponse<R>> {
    return service(options)
}
