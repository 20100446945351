import React, {useEffect, useState} from 'react';
import {
    ButtonCell,
    Cell,
    List,
    Section,
    Text,
    Input,
    Pagination,
    Modal, Caption
} from '@telegram-apps/telegram-ui';
import {FcBookmark, FcCalendar, FcHighPriority,} from "react-icons/fc";
import InfoFooter from "../component/InfoFooter";
import {
    ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {get_user_list_api, get_user_list_by_id_api, set_user_by_id_api} from "../api/AdminAPI";
import toast from "react-hot-toast";
import {ImCrying} from "react-icons/im";
import copy from "copy-to-clipboard";
import WebApp from "@twa-dev/sdk";


function UserManagement() {
    const [searchUserId, setSearchUserId] = useState("")
    const [pageIndex, setPageIndex] = useState(0);
    const [userList, setUserList] = useState([] as ResponseUserEntity[]);
    const [currentUser, setCurrentUser] = useState({} as ResponseUserEntity);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        update_user_list(searchUserId, pageIndex)
    }, [])
    const update_user_list = (userId: string, page: number) => {
        if (userId !== "") {
            if (isNaN(Number(userId))) {
                toast.error("请输入正确的ID")
                return
            }
            get_user_list_by_id_api(Number(userId))
                .then(res => {
                    setUserList(res.data.data)
                })
        } else {
            get_user_list_api(page)
                .then(res => {
                    setUserList(res.data.data)
                })
        }
    }
    const updateCurrentUser = (userId: number) => {
        get_user_list_by_id_api(Number(userId))
            .then(res => {
                setCurrentUser(res.data.data[0])
            })
    }
    return (
        <div>
            <List>
                <Section header="用户筛选">
                    <Input header="搜索" placeholder="请在这里输入用户的名称或ID"
                           type={"number"}
                           value={searchUserId}
                           onChange={e => {
                               setSearchUserId(e.target.value)
                               update_user_list(e.target.value, pageIndex)
                           }}/>
                </Section>
                <Section header="用户列表">
                    {
                        userList.length === 0 &&
                        <Cell before={<ImCrying size={24}/>} description="请输入正确的ID">暂无相关数据</Cell>
                    }
                    {
                        userList.map(item =>
                            <Cell Component="label"
                                  description={"ID:" + item.id}

                                  onClick={e => {
                                      setCurrentUser(item)
                                      setModalVisible(true)
                                  }}>
                                {item.name}</Cell>
                        )
                    }
                    {
                        searchUserId === "" &&
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <Pagination page={pageIndex}
                                        count={userList.length >= 30 ? 2 : 1}
                                        onChange={(event, page) => {
                                            setPageIndex(page)
                                            update_user_list(searchUserId, page)
                                        }}/>
                        </div>
                    }

                </Section>
                <InfoFooter/>
            </List>
            <Modal header={<ModalHeader></ModalHeader>}
                   open={modalVisible}
                   dismissible={true}
                   onOpenChange={(open) => setModalVisible(open)}>
                <List style={{background: 'var(--tgui--secondary_bg_color)', paddingBottom: "30px"}}>
                    <Section header="用户信息"
                             footer={
                                 <div style={{
                                     display: "flex",
                                     flexDirection: "column",
                                     rowGap: 5,
                                     padding: "10px 20px",
                                 }}>
                                     <Caption level="1" weight="3">
                                         以上操作将作为与超管的结算依据!
                                     </Caption>
                                     <Caption level="1" weight="3">
                                         请认真审核理由或收款后再点击！
                                     </Caption>
                                     <Caption level="1" weight="3">
                                         如需解除用户切换地区时限需联系超管！
                                     </Caption>
                                 </div>}>
                        <Cell
                            subtitle={"ID:" + currentUser.id}
                            onClick={e => {
                                copy(currentUser.id + "")
                                toast.success("已复制" + currentUser.id)
                            }}
                            after={"复制"}
                        >
                            {currentUser.name}
                        </Cell>
                        <Cell
                            before={<Text weight="2" style={{width: 80}}>注册时间</Text>}
                        >
                            {currentUser.reg_time}
                        </Cell>

                        <Cell
                            before={<Text weight="2" style={{width: 80}}>积分余额</Text>}
                        >
                            {currentUser.score}
                        </Cell>
                        <Cell
                            before={<Text weight="2" style={{width: 80}}>会员期限</Text>}
                        >

                            {currentUser.vip_timeout == null ? "未开通" : currentUser.vip_timeout}
                        </Cell>
                        <Cell
                            before={<Text weight="2" style={{width: 80}}>是否禁用</Text>}
                        >
                            {currentUser.baned === 1 ? "已禁用" : "未禁用"}
                        </Cell>
                        <Cell
                            before={<Text weight="2" style={{width: 80}}>邀请者ID</Text>}
                            onClick={e => {
                                if (currentUser.inviter_id == null) {
                                    toast.error("该用户暂无邀请者")
                                    return
                                }
                                copy(currentUser.id + "")
                                toast.success("已复制" + currentUser.id)
                            }}
                            after={"复制"}
                        >
                            {currentUser.inviter_id == null ? "无" : currentUser.inviter_id}
                        </Cell>
                        <ButtonCell before={<FcBookmark size={"20"}/>}
                                    onClick={e => {
                                        WebApp.showConfirm("确定赠送【50积分】吗？\n这将作为您与超管结算的凭证！", (r) => {
                                            if (r) {
                                                set_user_by_id_api(currentUser.id, 1, 1).then(res => {
                                                    toast.success(res.data.message)
                                                    updateCurrentUser(currentUser.id)
                                                    update_user_list(searchUserId, pageIndex)
                                                })
                                                WebApp.HapticFeedback.impactOccurred("soft")
                                            } else {
                                                return;
                                            }
                                        })
                                    }}>
                            赠送【50积分】
                        </ButtonCell>
                        <ButtonCell before={<FcCalendar size={"20"}/>}
                                    onClick={e => {
                                        WebApp.showConfirm("确定开通【月度会员】吗？\n这将作为您与超管结算的凭证！", (r) => {
                                            if (r) {
                                                set_user_by_id_api(currentUser.id, 2, 1).then(res => {
                                                    toast.success(res.data.message)
                                                    updateCurrentUser(currentUser.id)
                                                    update_user_list(searchUserId, pageIndex)
                                                })
                                                WebApp.HapticFeedback.impactOccurred("soft")
                                            } else {
                                                return;
                                            }
                                        })
                                    }}>
                            开通【月度会员】
                        </ButtonCell>
                        <ButtonCell before={<FcCalendar size={"20"}/>}
                                    onClick={e => {
                                        WebApp.showConfirm("确定开通【季度会员】吗？\n这将作为您与超管结算的凭证！", (r) => {
                                            if (r) {
                                                set_user_by_id_api(currentUser.id, 2, 2).then(res => {
                                                    toast.success(res.data.message)
                                                    updateCurrentUser(currentUser.id)
                                                    update_user_list(searchUserId, pageIndex)
                                                })
                                                WebApp.HapticFeedback.impactOccurred("soft")
                                            } else {
                                                return;
                                            }
                                        })
                                    }}>
                            开通【季度会员】
                        </ButtonCell>
                        <ButtonCell before={<FcCalendar size={"20"}/>}
                                    onClick={e => {
                                        WebApp.showConfirm("确定开通【永久会员】吗？\n这将作为您与超管结算的凭证！", (r) => {
                                            if (r) {
                                                set_user_by_id_api(currentUser.id, 2, 3).then(res => {
                                                    toast.success(res.data.message)
                                                    updateCurrentUser(currentUser.id)
                                                    update_user_list(searchUserId, pageIndex)
                                                })
                                                WebApp.HapticFeedback.impactOccurred("soft")
                                            } else {
                                                return;
                                            }
                                        })
                                    }}>
                            开通【永久会员】
                        </ButtonCell>
                        <ButtonCell before={<FcHighPriority size={"20"}/>}
                                    onClick={e => {
                                        WebApp.showConfirm("确定【禁用账号】吗？\n解禁需联系超管处理！", (r) => {
                                            if (r) {
                                                set_user_by_id_api(currentUser.id, 3, 1).then(res => {
                                                    toast.success(res.data.message)
                                                    updateCurrentUser(currentUser.id)
                                                    update_user_list(searchUserId, pageIndex)
                                                })
                                                WebApp.HapticFeedback.impactOccurred("soft")
                                            } else {
                                                return;
                                            }
                                        })
                                    }}>
                            禁用账号【解禁需联系超管】
                        </ButtonCell>
                    </Section>
                </List>
            </Modal>
        </div>

    );
}

export default UserManagement;
