import React from 'react';
import {Button, Text} from '@telegram-apps/telegram-ui';
import {FcGlobe, FcBrokenLink} from "react-icons/fc";
import InfoFooter from "../component/InfoFooter";
import {useNavigate} from "react-router-dom";

function ErrorPage() {
    const navigate = useNavigate()
    const BackToIndex = () => {
        navigate("/")
    }
    return (
        <div className="ErrorPage" style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: "20px"
        }}>
            <FcBrokenLink size={64}/>
            <Text weight="1" style={{textAlign: "center", width: "80%"}}> 抱歉，页面发生了一些意料之外的错误</Text>
            <Button
                before={<FcGlobe size={24}/>}
                mode="filled"
                size="s"
                onClick={BackToIndex}
            >
                返回主页
            </Button>
            <InfoFooter/>
        </div>
    );
}


export default ErrorPage;
