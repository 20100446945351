import React, {useEffect, useState} from 'react';
import {
    Cell,
    List,
    Section,
    Select,
    Pagination,
    ButtonCell,
    FixedLayout,
    SegmentedControl, Caption, Modal, Subheadline, Placeholder, Spinner
} from '@telegram-apps/telegram-ui';
import InfoFooter from "../component/InfoFooter";
import {FcIdea} from "react-icons/fc";
import {
    SegmentedControlItem
} from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import {get_district_list_api} from "../api/AreaAPI";
import {useLocation, useNavigate} from "react-router-dom";
import {NavigateOptions} from "react-router/dist/lib/context";
import {get_management_teacher_list_api} from "../api/AdminAPI";
import {ImCrying} from "react-icons/im";
import {
    ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {BiSolidUserDetail} from "react-icons/bi";
import {TbClipboardCopy, TbFlag2Filled, TbFlag2Off} from "react-icons/tb";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import WebApp from "@twa-dev/sdk";

function TeacherManagement() {
    const {state} = useLocation();
    const navigate = useNavigate();
    const [segmentState, setSegmentState] = useState(1)
    const [loadingState, setLoadingState] = useState(false)
    const [districtList, setDistrictList] = useState([] as ResponseAreaNodeEntity[])
    const [teacherList, setTeacherList] = useState([] as ResponseTeacherEntity[])
    const [selectDistrict, setSelectDistrict] = useState(-1)
    const [selectPage, setSelectPage] = useState(1)
    const [modalVisible, setModalVisible] = useState(false)
    const [currentTeacher, setCurrentTeacher] = useState({} as ResponseTeacherEntity)
    useEffect(() => {
        get_district_list_api({request_area_id: state.admin_city_id})
            .then(res => {
                setDistrictList(res.data.data)
            })
        update_teacher_list(selectDistrict, segmentState, selectPage)
    }, [])
    const update_teacher_list = (district_id: number, valid_state: number, page_index: number) => {
        setLoadingState(true)
        get_management_teacher_list_api({
            district_id: district_id === -1 ? null : district_id,
            valid_sate: valid_state,
            page_index: page_index,
        } as RequestManagementTeacherEntity)
            .then(res => {
                setLoadingState(false)
                setTeacherList(res.data.data)
            })
    }
    const onSegmentChange = (type: number) => {
        setSegmentState(type)
    }
    const onUnverifiedItemClick = (item: ResponseTeacherEntity) => {
        setCurrentTeacher(item)
        setModalVisible(true)
    }
    const getDistrictString = (district_id: number) => {
        let str: string = "错误"
        for (const responseAreaNodeEntity of districtList) {
            if (responseAreaNodeEntity.area_id === district_id) {
                str = responseAreaNodeEntity.name
                break
            }
        }
        return <Caption weight="3" style={{width: 50, textAlign: "right"}}> {str} </Caption>
    }
    const onToManagementPageBtnClick = (pageName: string, options?: NavigateOptions) => {
        navigate("/" + pageName, options)
    }
    return (
        <div>
            <List style={{minHeight: "100vh"}}>
                <Section header="新增">
                    <ButtonCell before={<FcIdea size={"20"}/>}
                                onClick={() => onToManagementPageBtnClick("TeacherCreator", {state: {type: 1}})}>
                        [新增茶艺师]
                    </ButtonCell>
                </Section>
                <Section header="筛选">
                    <Cell style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <SegmentedControl>
                            <SegmentedControlItem
                                onClick={() => {
                                    onSegmentChange(0)
                                    update_teacher_list(selectDistrict, 0, selectPage)
                                }}
                                selected={segmentState === 0}>
                                未验证
                            </SegmentedControlItem>
                            <SegmentedControlItem
                                onClick={() => {
                                    onSegmentChange(1)
                                    update_teacher_list(selectDistrict, 1, selectPage)
                                }}
                                selected={segmentState === 1}>
                                已验证
                            </SegmentedControlItem>
                        </SegmentedControl>
                    </Cell>
                    <Select header="地区筛选" value={selectDistrict}
                            onChange={event => {
                                setSelectDistrict(Number(event.target.value))
                                update_teacher_list(Number(event.target.value), segmentState, selectPage)
                            }}
                            style={{backgroundColor:"var(--tgui--section_bg_color"}}>
                        <option value={-1}>不限区域</option>
                        {districtList.map(item => <option value={item.area_id}>{item.name}</option>)}
                    </Select>

                </Section>
                {
                    loadingState &&
                    <Section header={"精彩加载中，请稍等片刻..."}>
                        <Placeholder description="正在加载茶艺师列表...">
                            <Spinner size="s"/>
                        </Placeholder>
                    </Section>
                }
                {
                    !loadingState &&
                    <Section header="茶艺师列表" footer="每页最多显示30条，前方标志表示是否已上岸，括号内显示年龄">
                        {
                            teacherList.length === 0 &&
                            <Cell before={<ImCrying size={24}/>} description="请切换筛选条件...">暂无相关数据</Cell>
                        }
                        {
                            teacherList.map(item =>
                                <Cell description={"注册时间：" + item.reg_time}
                                      before={item.baned === 0 ? <TbFlag2Filled size={24}/> : <TbFlag2Off size={24}/>}
                                      after={<Subheadline level="1" weight="3" style={{width: 60, textAlign: "right"}}>
                                          {getDistrictString(item.district)}
                                      </Subheadline>}
                                      onClick={e => {
                                          onUnverifiedItemClick(item)
                                      }}>
                                    {item.name + "[" + item.age + "]"}</Cell>)
                        }
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <Pagination page={selectPage}
                                        count={teacherList.length >= 30 ? 2 : 1}
                                        onChange={(event, page) => {
                                            setSelectPage(page)
                                            update_teacher_list(selectDistrict, segmentState, page)
                                        }}/>
                        </div>
                    </Section>
                }
                <InfoFooter/>
            </List>
            <Modal open={modalVisible}
                   dismissible={true}
                   onOpenChange={(open) => setModalVisible(open)}
                   header={<ModalHeader></ModalHeader>}
            >
                <List style={{paddingBottom: '30px', background: 'var(--tgui--secondary_bg_color)'}}>
                    <Section header="请选择您要对该条信息进行的操作">
                        {
                            segmentState == 1 &&
                            <ButtonCell before={<BiSolidUserDetail size={24}/>}
                                        onClick={() => {
                                            WebApp.HapticFeedback.impactOccurred("soft")
                                            navigate("/TeacherDetail", {
                                                state: {
                                                    teacher_id: currentTeacher.id,
                                                }
                                            })
                                        }}>
                                【预览详情】进入详情页
                            </ButtonCell>
                        }
                        <ButtonCell before={<BiSolidUserDetail size={24}/>}
                                    onClick={() => onToManagementPageBtnClick("TeacherEdit", {state: {teacher_id: currentTeacher.id}})}>
                            【编辑模式】编辑/审核
                        </ButtonCell>
                        <ButtonCell before={<TbClipboardCopy size={24}/>}
                                    onClick={() => {
                                        copy(currentTeacher.id)
                                        toast.success("已复制茶艺师ID：\n" + currentTeacher.id)
                                    }}>
                            【复制】茶艺师ID
                        </ButtonCell>
                        <ButtonCell before={<TbClipboardCopy size={24}/>}
                                    onClick={() => {
                                        copy("" + currentTeacher.submit_user_id)
                                        toast.success("已复制提交用户ID：\n" + currentTeacher.submit_user_id)
                                    }}>
                            【复制】用户ID
                        </ButtonCell>
                    </Section>
                </List>
            </Modal>
        </div>
    );
}

export default TeacherManagement;
