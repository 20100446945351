import React, {useEffect, useState} from 'react';
import {
    ButtonCell,
    Cell,
    FixedLayout,
    List,
    Modal,
    Placeholder,
    Section,
    SegmentedControl
} from '@telegram-apps/telegram-ui';
import InfoFooter from "../component/InfoFooter";
import {useNavigate} from "react-router-dom";
import {
    SegmentedControlItem
} from '@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem';
import {TbClipboardCopy, TbFaceId, TbFaceIdError} from "react-icons/tb";
import {get_errors_api, valid_errors_api} from "../api/AdminAPI";
import toast from "react-hot-toast";
import {ImCrying} from "react-icons/im";
import {
    ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import copy from "copy-to-clipboard";
import {BiSolidUserDetail} from "react-icons/bi";
import WebApp from "@twa-dev/sdk";

function ErrorManagementPage() {
    const navigate = useNavigate()
    const [segmentState, setSegmentState] = useState(-1)
    const [errorList, setErrorList] = useState([] as ResponseErrorEntity[])
    const [currentTeacher, setCurrentTeacher] = useState({} as ResponseErrorEntity)
    const [unverifiedModalVisible, setUnverifiedModalVisible] = useState(false)
    useEffect(() => {
        loadErrorList(segmentState)
    }, [])
    const onSegmentChange = (type: number) => {
        setSegmentState(type)
        loadErrorList(type)
    }
    const loadErrorList = (type: number) => {
        get_errors_api(type)
            .then((res) => {
                setErrorList(res.data.data)
            })
    }
    const onUnverifiedItemClick = (item: ResponseErrorEntity) => {
        setCurrentTeacher(item)
        setUnverifiedModalVisible(true)
    }
    const unverifiedModalVisibleChange = (visible: boolean) => {
        setUnverifiedModalVisible(visible)
    }
    const onToValidBtnClick = (error_id: number, type: number) => {
        valid_errors_api(error_id, type).then((res) => {
            setUnverifiedModalVisible(false)
            loadErrorList(segmentState)
            toast.success(res.data.message)
        })
    }
    const onToTeacherDetailBtnClick = (teacher_id: string) => {
        setUnverifiedModalVisible(false)
        navigate("/TeacherDetail", {
            state: {
                teacher_id: teacher_id,
            }
        })
    }
    return (
        <div>

            <List>
                <Section header="筛选">
                    <Cell>
                        <SegmentedControl>
                            <SegmentedControlItem
                                onClick={() => {
                                    onSegmentChange(-1)
                                }}
                                selected={segmentState === -1}
                            >
                                未验证
                            </SegmentedControlItem>
                            <SegmentedControlItem
                                onClick={() => {
                                    onSegmentChange(1)
                                }}
                                selected={segmentState === 1}
                            >
                                已验证
                            </SegmentedControlItem>
                        </SegmentedControl>
                    </Cell>
                </Section>
                <Section
                    footer="⚠警告：验证不通过的错误申请，将被永久移出，无法恢复！通过的错误无法删除！请管理员确认信息后再点击！">
                    {
                        errorList.length === 0 &&
                        <Section header={"本区暂无数据"}>
                            <Placeholder description="本类型下暂无相关数据...">
                                <ImCrying size={48}/>
                            </Placeholder>
                        </Section>
                    }
                    {
                        errorList.map(item =>
                            <Cell description={item.content}
                                  subtitle={item.create_time}
                                  multiline
                                  onClick={() => {
                                      onUnverifiedItemClick(item)
                                  }}
                            >
                            </Cell>
                        )
                    }
                </Section>
                <InfoFooter/>
            </List>
            <Modal open={unverifiedModalVisible}
                   dismissible={true}
                   onOpenChange={(open) => {
                       unverifiedModalVisibleChange(open)
                   }}
                   header={<ModalHeader></ModalHeader>}
            >
                <List style={{paddingBottom: '30px', background: 'var(--tgui--secondary_bg_color)'}}>
                    <Section header="请选择您要对该条错误的操作">
                        <ButtonCell before={<BiSolidUserDetail size={24}/>}
                                    onClick={() => {
                                        onToTeacherDetailBtnClick(currentTeacher.teacher_id)
                                    }}
                        >
                            【查看】前往茶艺师详情
                        </ButtonCell>
                        {
                            segmentState === -1 &&
                            <div>
                                <ButtonCell before={<TbFaceId size={24}/>}
                                            onClick={() => {
                                                WebApp.showConfirm("确定错误被修复吗？（请根据真实反馈修改，例如：茶艺师联系方式或是否上岸，确认后将会给予用户积分奖励且不可撤回！）", (r) => {
                                                    if (r === true) {
                                                        onToValidBtnClick(currentTeacher.id, 1)
                                                    }
                                                })
                                            }}
                                >
                                    【通过】该条错误正确（发奖励）
                                </ButtonCell>
                                <ButtonCell before={<TbFaceIdError size={24}/>}
                                            onClick={() => {
                                                WebApp.showConfirm("确定标记为误报吗？（确认误报将删除该条数据！）", (r) => {
                                                    if (r === true) {
                                                        onToValidBtnClick(currentTeacher.id, 0)
                                                    }
                                                })
                                            }}
                                >
                                    【驳回】该条错误为误报
                                </ButtonCell>
                            </div>
                        }
                        <ButtonCell before={<TbClipboardCopy size={24}/>}
                                    onClick={() => {
                                        copy(currentTeacher.teacher_id)
                                        toast.success("已复制茶艺师ID：\n" + currentTeacher.teacher_id)
                                    }}>
                            【复制】茶艺师ID
                        </ButtonCell>
                        <ButtonCell before={<TbClipboardCopy size={24}/>}
                                    onClick={() => {
                                        copy("" + currentTeacher.user_id)
                                        toast.success("已复制提交用户ID：\n" + currentTeacher.user_id)

                                    }}>
                            【复制】用户ID
                        </ButtonCell>
                    </Section>
                </List>
            </Modal>
        </div>
    );
}


export default ErrorManagementPage;
