import React, {useEffect, useState} from 'react';
import {
    Cell,
    List,
    Section,
    Input,
    Button, Modal, Placeholder, Spinner
} from '@telegram-apps/telegram-ui';
import {FaCheckCircle,} from "react-icons/fa";
import InfoFooter from "../component/InfoFooter";
import {
    delete_admin_api,
    get_admin_list_api, get_operation_list_by_admin_id,
    set_admin_api,
} from "../api/AdminAPI";
import {ImCrying} from "react-icons/im";
import toast from "react-hot-toast";
import WebApp from "@twa-dev/sdk";
import copy from "copy-to-clipboard";
import {
    ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";

function AdminSetting() {
    const [adminList, setAdminList] = useState([] as ResponseAdminEntity[])
    const [loadingFlag, setLoadingFlag] = useState(false)
    const [inputId, setInputId] = useState(0)
    const [inputCity, setInputCity] = useState(0)
    const [inputUrl, setInputUrl] = useState("")
    const [inputPayment, setInputPayment] = useState("")
    const [modalVisible, setModalVisible] = useState(false)
    const [adminOpList, setAdminOpList] = useState([] as ResponseAdminOperationEntity[])
    useEffect(() => {
        load_admin_list()
    }, [])

    const onInsertNewAdminBtnClick = () => {
        WebApp.showConfirm("确定添加管理员吗？\n请慎重！", (r) => {
            if (r) {
                if (inputId === 0 || inputCity === 0 || inputUrl === ""|| inputPayment === "") {
                    toast.error("请输入正确的信息！")
                    return;
                }
                set_admin_api(inputId, inputCity, inputUrl,inputPayment)
                    .then(res => {
                        toast.success(res.data.message)
                        setInputId(0)
                        setInputCity(0)
                        setInputUrl("")
                        load_admin_list()
                    })
            } else {
                return;
            }
        })
    }
    const onDeleteAdminBtnClick = (adminId: number) => {
        WebApp.showConfirm("确定移除管理员【" + adminId + "】吗？", (r) => {
            if (r) {
                delete_admin_api(adminId)
                    .then(res => {
                        toast.success(res.data.message)
                        load_admin_list()
                    })
            } else {
                return;
            }
        })
    }
    const load_admin_list = () => {
        get_admin_list_api()
            .then(res => {
                setAdminList(res.data.data)
            })
    }
    return (
        <div>
            <List>
                <Section header="管理员列表"
                         footer="【提示】一个地区最好仅设置一位管理员，地区用户联系时会联系管理员中的第一个！">
                    {
                        adminList.length === 0 &&
                        <Cell before={<ImCrying size={24}/>} description="请先设置管理员">暂无相关数据</Cell>
                    }
                    {
                        adminList.map(item =>
                            <div>
                                <Cell
                                    subhead={"[Lv." + item.level + "]地区ID:" + item.city}
                                    subtitle={"" + item.url}
                                    description={"" + item.payment}
                                > {item.id} </Cell>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    height: 70,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    columnGap: 5
                                }}>
                                    <Button mode="filled" size="s"
                                            onClick={e => {
                                                copy(item.url)
                                                toast.success("已复制：" + item.url)
                                            }}> 复制链接 </Button>
                                    <Button mode="filled" size="s"
                                            onClick={e => {
                                                WebApp.showConfirm("打开管理员【" + item.id + "】链接？", (r) => {
                                                    if (r) {
                                                        WebApp.openTelegramLink(item.url)
                                                    } else {
                                                        return;
                                                    }
                                                })
                                            }}> 跳转 </Button>
                                    <Button mode="filled" size="s"
                                            onClick={e => onDeleteAdminBtnClick(item.id)}> 移除 </Button>
                                    <Button mode="filled" size="s"
                                            onClick={e => {
                                                setAdminOpList([])
                                                setModalVisible(true)
                                                setLoadingFlag(true)
                                                get_operation_list_by_admin_id(item.id)
                                                    .then(res => {
                                                        setLoadingFlag(false)
                                                        setAdminOpList(res.data.data)
                                                    })
                                            }}> 结算 </Button>
                                </div>
                            </div>
                        )
                    }
                </Section>
                <Section header={"新增管理员"}>
                    <Input header="输入管理员ID" placeholder="输入管理员ID"
                           value={inputId === 0 ? "" : inputId}
                           type={"number"}
                           onChange={e => setInputId(Number(e.target.value))}/>
                    <Input header="输入CityId" placeholder="输入CityId"
                           value={inputCity === 0 ? "" : inputCity}
                           type={"number"}
                           onChange={e => setInputCity(Number(e.target.value))}/>
                    <Input header="输入URL" placeholder="输入URL"
                           value={inputUrl}
                           onChange={e => setInputUrl(e.target.value)}/>
                    <Input header="输入其收款码" placeholder="输入收款码"
                           value={inputPayment}
                           onChange={e => setInputPayment(e.target.value)}/>
                    <div style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "60px"
                    }}>
                        <Button style={{width: "80%"}}
                                before={<FaCheckCircle size={16}/>}
                                mode="filled"
                                size="m"
                                onClick={e => onInsertNewAdminBtnClick()}
                        >添加 </Button>
                    </div>
                </Section>
                <InfoFooter/>

            </List>
            <Modal header={<ModalHeader></ModalHeader>}
                   open={modalVisible}
                   dismissible={true}
                   onOpenChange={(open) => setModalVisible(open)}>
                <List style={{background: 'var(--tgui--secondary_bg_color)', paddingBottom: "30px"}}>
                    {
                        loadingFlag &&
                        <Section header={"请稍等片刻..."}>
                            <Placeholder description="正在加载管理员操作数据...">
                                <Spinner size="s"/>
                            </Placeholder>
                        </Section>
                    }
                    {
                        !loadingFlag &&
                        <Section header={"操作列表（Limit 1000）"}>
                            {adminOpList.length!==0&&
                                adminOpList.map((item, index) =>
                                    <Cell subhead={index + "|Admin:" + item.admin_id}
                                          before={item.operation}
                                          after={item.value}
                                          description={"Time:" + item.operation_time}
                                    >
                                        {"用户【" + item.target_id + "】"}
                                    </Cell>
                                )
                            }
                            {adminOpList.length===0&&
                                <Placeholder description="暂无操作数据">
                                </Placeholder>
                            }
                        </Section>
                    }
                </List>
            </Modal>
        </div>
    );
}

export default AdminSetting;
