import React, {useEffect, useState} from 'react';
import {
    Cell,
    List,
    Section,
    Input,
    Button
} from '@telegram-apps/telegram-ui';
import {FaCheckCircle,} from "react-icons/fa";
import InfoFooter from "../component/InfoFooter";
import {get_outstanding_list_api, set_outstanding_teacher_api} from "../api/AdminAPI";
import {ImCrying} from "react-icons/im";
import toast from "react-hot-toast";
import WebApp from "@twa-dev/sdk";


function OutstandingSetting() {
    const [outstandingList, setOutstandingList] = useState([] as ResponseTeacherEntity[])
    const [inputId, setInputId] = useState("")
    useEffect(() => {
        load_outstanding_list()
    }, [])

    const onInsertNewOutstandingTeacherBtnClick = () => {
        if (inputId === "") {
            toast.error("请填入正确的茶艺师ID")
            return
        }
        set_outstanding_teacher_api(inputId, 1)
            .then(res => {
                toast.success(res.data.message)
                setInputId("")
                load_outstanding_list()
            })
    }
    const onDeleteOutstandingTeacherBtnClick = (teacher_id: string) => {
        WebApp.showConfirm("确定取消推荐该茶艺师吗？", (r) => {
            if (r) {
                set_outstanding_teacher_api(teacher_id, -1)
                    .then(res => {
                        toast.success(res.data.message)
                        load_outstanding_list()
                    })
            } else {
                return;
            }
        })
    }
    const load_outstanding_list = () => {
        get_outstanding_list_api()
            .then(res => {
                setOutstandingList(res.data.data)
            })
    }
    return (
        <List>
            <Section header="推荐茶艺师列表" footer="【提示】建议设置数量3-5个">
                {
                    outstandingList.length === 0 &&
                    <Cell before={<ImCrying size={24}/>} description="请先设置推荐茶艺师">暂无相关数据</Cell>
                }
                {
                    outstandingList.map(item =>
                        <Cell after={<Button mode="filled" size="m"
                                             onClick={e => onDeleteOutstandingTeacherBtnClick(item.id)}> 移除 </Button>}
                              description={"ID:" + item.id}
                        > {item.name} </Cell>
                    )
                }
            </Section>
            <Input header="推荐茶艺师" placeholder="推荐茶艺师 输入ID"
                   value={inputId}
                   onChange={e => setInputId(e.target.value)}
                   after={<Button before={<FaCheckCircle/>} mode="filled" size="m"
                                  onClick={e => onInsertNewOutstandingTeacherBtnClick()}
                   >添加 </Button>}
            />
            <InfoFooter/>
        </List>
    );
}

export default OutstandingSetting;
