import React, {useEffect, useState} from 'react';
import {
    Cell,
    List,
    Section,
    Input,
    Select,
    Button,
    Textarea,
    ButtonCell,
    Blockquote,
    FileInput,
    Image, Caption, Placeholder, Spinner, Switch
} from '@telegram-apps/telegram-ui';
import {FaCheckCircle} from "react-icons/fa";
import {FcCheckmark, FcOk, FcUndo,} from "react-icons/fc";
import toast from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";
import {get_city_list_api, get_district_list_api, get_parent_id_api, get_province_list_api} from "../api/AreaAPI";
import InfoFooter from "../component/InfoFooter";
import {TbHomeDot} from "react-icons/tb";
import {get_management_teacher_detail_api, get_photo_base64_api_sync, update_teacher_api} from "../api/AdminAPI";
import WebApp from "@twa-dev/sdk";


function TeacherEdit() {
    const navigate = useNavigate()
    const {state} = useLocation();
    //编辑模式0->未验证模式 1->已验证模式
    const [editType, setEditType] = useState(0)
    //网络状态 模式：-1->加载中 0->填写 1->上传中 2->上传完成
    const [uploadingState, setUploadingState] = useState(-1)
    const [imgList, setImgList] = useState([] as ImageEntity[])
    //茶艺师信息对象
    const [teacherId, setTeacherId] = useState("")
    const [teacherName, setTeacherName] = useState("")
    const [teacherAge, setTeacherAge] = useState(30)
    const [teacherPriceVideo, setTeacherPriceVideo] = useState(0)
    const [teacherPriceEasy, setTeacherPriceEasy] = useState(0)
    const [teacherPriceNormal, setTeacherPriceNormal] = useState(0)
    const [teacherPriceTwo, setTeacherPriceTwo] = useState(0)
    const [teacherPriceNight, setTeacherPriceNight] = useState(0)
    const [teacherConnect, setTeacherConnect] = useState([] as ConnectEntity[])
    const [selectProvince, setSelectProvince] = useState(0)
    const [selectCity, setSelectCity] = useState(0)
    const [selectDistrict, setSelectDistrict] = useState(0)
    const [teacherTown, setTeacherTown] = useState("")
    const [teacherAddress, setTeacherAddress] = useState("")
    const [teacherRemark, setTeacherRemark] = useState("")
    const [teacherType, setTeacherType] = useState(1)
    const [teacherBaned, setTeacherBaned] = useState(0)
    const [insertConnectType, setInsertConnectType] = useState("Wechat")
    const [insertConnectContent, setInsertConnectContent] = useState("")
    const [provinceList, setProvinceList] = useState([] as ResponseAreaNodeEntity[])
    const [cityList, setCityList] = useState([] as ResponseAreaNodeEntity[])
    const [districtList, setDistrictList] = useState([] as ResponseAreaNodeEntity[])
    const [onSubmitSuccessStr, setOnSubmitSuccessStr] = useState("")

    useEffect(() => {
        get_management_teacher_detail_api({teacher_id: state.teacher_id} as RequestTeacherEntityById)
            .then(async res => {
                setTeacherId(res.data.data.teacher.id)
                setTeacherName(res.data.data.teacher.name)
                setTeacherAge(res.data.data.teacher.age)
                setTeacherPriceVideo(res.data.data.teacher.price_video)
                setTeacherPriceEasy(res.data.data.teacher.price_easy)
                setTeacherPriceNormal(res.data.data.teacher.price_normal)
                setTeacherPriceTwo(res.data.data.teacher.price_two)
                setTeacherPriceNight(res.data.data.teacher.price_night)
                setTeacherConnect(JSON.parse(res.data.data.teacher.connect))
                loadProvinceList(res.data.data.teacher.city, res.data.data.teacher.district)
                setTeacherTown(res.data.data.teacher.town)
                setTeacherAddress(res.data.data.teacher.address)
                setTeacherRemark(res.data.data.teacher.remark)
                setTeacherType(res.data.data.teacher.type)
                setTeacherBaned(res.data.data.teacher.baned)
                await loadPhotos(res.data.data.teacher.id, res.data.data.photoList)
                setEditType(res.data.data.teacher.verify)
                setUploadingState(0)
            })
    }, [])

    async function loadPhotos(teacherId: string, photoList: string[]) {
        let imgList = [] as ImageEntity[];
        for (const photo_name of photoList) {
            try {
                const res4photo = await get_photo_base64_api_sync(teacherId, photo_name);
                imgList.push(
                    {
                        name: "图片" + (photoList.indexOf(photo_name) + 1) + ".png",
                        data: res4photo.data.data,
                        size: res4photo.data.data.length / 1024 / 1024
                    } as ImageEntity
                );
                setImgList(imgList);
            } catch (error) {
                console.error("加载图片失败：", error);
            }
        }
    }

    const loadProvinceList = (city_id: number, district_id: number) => {
        get_province_list_api()
            .then((res) => {
                setProvinceList(res.data.data)
                setSelectProvince(res.data.data[0].area_id)
                get_parent_id_api({request_area_id: city_id} as RequestAreaEntity)
                    .then((res) => {
                        setSelectProvince(res.data.data)
                        loadCityList(res.data.data, district_id)
                    })
            })
    }
    const loadCityList = (provinceVal: number, district_id: number | null) => {
        setSelectProvince(provinceVal)
        get_city_list_api({request_area_id: provinceVal} as RequestAreaEntity)
            .then((res) => {
                setCityList(res.data.data)
                setSelectCity(res.data.data[0].area_id)
                loadDistrictList(res.data.data[0].area_id, district_id)
            })
    }
    const loadDistrictList = (cityVal: number, district_id: number | null) => {
        setSelectCity(cityVal)
        get_district_list_api({request_area_id: cityVal} as RequestAreaEntity)
            .then((res) => {
                setDistrictList(res.data.data)
                setSelectDistrict(district_id == null ? res.data.data[0].area_id : district_id)
            })
    }
    const onImgUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files == null || event.currentTarget.files.length <= 0) {
            toast.error("图片上传失败")
            return
        }
        let imageFile = event.currentTarget.files[0];///获得input的第一个图片
        if (imageFile.size > (1024 * 5 * 1000)) {
            toast.error("文件大于5MB，请重新上传")
            return;
        }
        //使用readAsDataURL来进行回显图片
        let reader = new FileReader();//filereader.readasdataurl读取图像文件转换为流
        reader.readAsDataURL(imageFile);
        reader.onload = function (event) {//读取数据时会触发一个load事件
            let imageStr = this.result as string
            if ((imageStr.length / 1024 / 1024) >= 5) {
                toast.error("文件大于5MB，请重新上传")
                return;
            }
            imgList.push({name: imageFile.name, size: imageFile.size / 1024 / 1024, data: imageStr} as ImageEntity)
            let imgListCopy = [] as ImageEntity[]
            Object.assign(imgListCopy, imgList)
            setImgList(imgListCopy)
        };
    }
    const onInsertConnectBtnClick = () => {
        if (insertConnectContent === "") {
            toast.error("新增联系方式不能为空")
            return
        }
        let connectItem = {type: insertConnectType, value: insertConnectContent} as ConnectEntity
        let connectListCopy = [] as ConnectEntity[]
        Object.assign(connectListCopy, teacherConnect)
        connectListCopy.push(connectItem)
        setTeacherConnect(connectListCopy)
        toast.success("新增" + transformConnect(connectItem.type) + "成功")
        setInsertConnectContent("")
    }
    const onDeleteImgBtnClick = (index: number) => {
        WebApp.showConfirm("确定删除这项吗？", (r) => {
            if (r === false) {
                return
            }
            let imgListCopy = [] as ImageEntity[]
            for (let i = 0; i < imgList.length; i++) {
                if (i !== index) {
                    imgListCopy.push(imgList[i])
                } else {
                    toast.success("已删除：" + imgList[i].name)
                }
            }
            setImgList(imgListCopy)
        })
    }
    const onDeleteConnectBtnClick = (index: number) => {
        WebApp.showConfirm("确定删除这项吗？", (r) => {
            if (r === false) {
                return
            }
            let connectListCopy = [] as ConnectEntity[]
            for (let i = 0; i < teacherConnect.length; i++) {
                if (i !== index) {
                    connectListCopy.push(teacherConnect[i])
                } else {
                    toast.success("已删除：" + teacherConnect[i].value)
                }
            }
            setTeacherConnect(connectListCopy)
        })
    }
    const onSubmitBtnClick = () => {
        if (teacherName === "") return toast.error("茶艺师名称不能为空")
        if (teacherAge === 0) return toast.error("茶艺师年龄不能为空")
        if (teacherTown === "") return toast.error("茶艺师大地名不能为空")
        if (teacherAddress === "") return toast.error("茶艺师详细地址不能为空")
        if (teacherRemark === "") return toast.error("茶艺师备注不能为空")
        if (teacherPriceVideo === 0 && teacherPriceEasy === 0 && teacherPriceNormal === 0 && teacherPriceTwo === 0 && teacherPriceNight === 0) return toast.error("至少添加一项服务价格")
        if (teacherConnect.length === 0) return toast.error("至少添加一项联系方式")
        if (imgList.length === 0) return toast.error("至少添加一张展示图片")
        let teacher_entity: RequestTeacherEntity = {} as RequestTeacherEntity
        teacher_entity.id = teacherId
        teacher_entity.name = teacherName
        teacher_entity.age = teacherAge
        teacher_entity.type = teacherType
        teacher_entity.city = selectCity
        teacher_entity.district = selectDistrict
        teacher_entity.town = teacherTown
        teacher_entity.address = teacherAddress
        teacher_entity.remark = teacherRemark

        teacher_entity.price_video = teacherPriceVideo
        teacher_entity.price_easy = teacherPriceEasy
        teacher_entity.price_normal = teacherPriceNormal
        teacher_entity.price_two = teacherPriceTwo
        teacher_entity.price_night = teacherPriceNight

        teacher_entity.connect = JSON.stringify(teacherConnect);
        teacher_entity.baned = teacherBaned;

        let teacher_image_list: string[] = []
        for (const imageEntity of imgList) {
            teacher_image_list.push(imageEntity.data)
        }
        setUploadingState(1)
        update_teacher_api({
            teacher_entity: teacher_entity,
            teacher_image_list: teacher_image_list
        } as RequestNewTeacherEntity).then(res => {
            toast.success(res.data.message)
            setOnSubmitSuccessStr(res.data.message)
            setUploadingState(2)
        })
    }
    const transformConnect = (type: string) => {
        switch (type) {
            case "Phone":
                return "联系电话"
            case "Tencent":
                return "QQ号"
            case "Wechat":
                return "微信号"
            case "Telegram":
                return "电报链接"
            case "Potato":
                return "土豆号"
            case "Bat":
                return "蝙蝠号"
            case "WithYou":
                return "与你号"
        }
        return "其他"
    }
    return (
        <List>
            {uploadingState === -1 &&
                <Section>
                    <Placeholder
                        header="正在加载中"
                        description="请稍后，请勿退出或切换应用..."
                    >
                        <Spinner size="l"/>
                    </Placeholder>
                </Section>
            }
            {uploadingState === 0 &&
                <div>
                    <Cell>
                        <Blockquote type="text" style={{whiteSpace: "pre-wrap",backgroundColor:"#2990FF33"}}>
                        {editType === 0 && "该茶艺师尚未验证，请注意审核！\n1.核实并修正联系方式、图片、价格等；\n2.点击验证；"}
                            {editType === 1 && "该茶艺师是已验证，请核实/修改相关信息后，再点击验证！"}
                        </Blockquote>
                    </Cell>
                    <Section header="🥻基础信息" footer="本部分均为必填项，请仔细核对后填写。" >
                        <Input before={<Caption weight="3" style={{width: 40}}> 名称 </Caption>}
                               header="茶艺师名称" placeholder="茶艺师名称 例如：小猫"
                               value={teacherName}
                               onChange={e => setTeacherName(e.target.value)}/>
                        <Input before={<Caption weight="3" style={{width: 40}}> 年龄 </Caption>}
                               header="年龄" placeholder="年龄 岁" type="number"
                               value={teacherAge}
                               onChange={e => setTeacherAge(Number(e.target.value))}/>
                        <Select header="类型"
                                value={teacherType}
                                onChange={e => setTeacherType(Number(e.target.value))}>
                            <option value={1}>全职，通常自带品茶房间</option>
                            <option value={2}>兼职，另需用户预约房间</option>
                        </Select>
                        <Select header="所在省级城市" value={selectProvince}
                                onChange={e => loadCityList(Number(e.target.value), null)}>
                            {provinceList.map(item => <option value={item.area_id}>{item.name}</option>)}
                        </Select>
                        <Select header="所在市级城市" value={selectCity}
                                onChange={e => loadDistrictList(Number(e.target.value), null)}>
                            {cityList.map(item => <option value={item.area_id}>{item.name}</option>)}
                        </Select>
                        <Select header="所在区级城市" value={selectDistrict}
                                onChange={e => setSelectDistrict(Number(e.target.value))}>
                            {districtList.map(item => <option value={item.area_id}>{item.name}</option>)}
                        </Select>
                        <Input before={<Caption weight="3" style={{width: 40}}> 地名 </Caption>}
                               header="稍大地名" placeholder="稍大地名 例如：时代天街"
                               value={teacherTown}
                               onChange={e => setTeacherTown(e.target.value)}/>
                        <Input before={<Caption weight="3" style={{width: 40}}> 位置 </Caption>}
                               header="精确位置" placeholder="精确位置 例如：xx小区"
                               value={teacherAddress}
                               onChange={e => setTeacherAddress(e.target.value)}/>
                        <Textarea style={{minHeight: "150px"}}
                                  header="基本介绍" placeholder="基本介绍 例如：该老师服务态度好，很体贴细心等..."
                                  value={teacherRemark}
                                  onChange={e => setTeacherRemark(e.target.value)}/>
                    </Section>
                    <Section header="👑️服务价格" footer="提示：至少需要填写一种价格。如某项目价格未知，请留空或填0">
                        <Input before={<Caption weight="3" style={{width: 80}}> 视频指导 </Caption>}
                               header="视频/图片价格" placeholder="视频/图片价格" type="number"
                               value={(teacherPriceVideo === 0 ? undefined : teacherPriceVideo)}
                               onChange={e => setTeacherPriceVideo(Number(e.target.value))}/>
                        <Input before={<Caption weight="3" style={{width: 80}}> 基础指导 </Caption>}
                               header="基础服务"
                               placeholder="基础服务 价格" type="number"
                               value={(teacherPriceEasy === 0 ? undefined : teacherPriceEasy)}
                               onChange={e => setTeacherPriceEasy(Number(e.target.value))}/>
                        <Input before={<Caption weight="3" style={{width: 80}}> 全套指导 </Caption>}
                               header="全套服务" placeholder="全套服务 价格" type="number"
                               value={(teacherPriceNormal === 0 ? undefined : teacherPriceNormal)}
                               onChange={e => setTeacherPriceNormal(Number(e.target.value))}/>
                        <Input before={<Caption weight="3" style={{width: 80}}> 两次指导 </Caption>}
                               header="两次服务" placeholder="两次服务 价格" type="number"
                               value={(teacherPriceTwo === 0 ? undefined : teacherPriceTwo)}
                               onChange={e => setTeacherPriceTwo(Number(e.target.value))}/>
                        <Input before={<Caption weight="3" style={{width: 80}}> 包时指导 </Caption>}
                               header="包时服务" placeholder="包时服务 价格" type="number"
                               value={(teacherPriceNight === 0 ? undefined : teacherPriceNight)}
                               onChange={e => setTeacherPriceNight(Number(e.target.value))}/>
                    </Section>
                    <Section header="📲联系方式"
                             footer="若要编辑某项联系方式,请复制后重新添加，待添加完成后再移除旧的项目。">
                        {
                            teacherConnect.map((item, index) =>
                                <Cell after={<Button mode="filled" size="s"
                                                     onClick={() => onDeleteConnectBtnClick(index)}
                                > 移除 </Button>}
                                      description={transformConnect(item.type)}
                                > {item.value} </Cell>
                            )
                        }
                        <Input header="新增联系方式" placeholder="新增联系方式内容" value={insertConnectContent}
                               onChange={e => setInsertConnectContent(e.target.value)}/>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minHeight: "60px"
                        }}>
                            <Select header="类型" defaultValue={insertConnectType}
                                    onChange={e => setInsertConnectType(e.target.value)}>
                                <option value={"Phone"}>联系电话</option>
                                <option value={"Tencent"}>QQ号</option>
                                <option value={"Wechat"}>微信号</option>
                                <option value={"Telegram"}>电报链接</option>
                                <option value={"Potato"}>土豆号</option>
                                <option value={"Bat"}>蝙蝠号</option>
                                <option value={"WithYou"}>与你号</option>
                            </Select>
                            <Button style={{marginRight: "18px"}}
                                    before={<FaCheckCircle/>}
                                    mode="filled"
                                    size="s"
                                    onClick={e => {
                                        onInsertConnectBtnClick()
                                    }}
                            >点击添加</Button>
                        </div>
                    </Section>
                    <Section header="其他信息" footer="设置成为上岸的茶艺师将不会在用户列表中出现">
                        <Cell after={<Switch checked={(teacherBaned === 1)} onClick={e => {
                            setTeacherBaned((teacherBaned === 1 ? 0 : 1))
                        }}/>}>
                            是否已经上岸
                        </Cell>
                    </Section>
                    <Section header="🎥展示图片" footer="目前仅支持上传图片，且单张图片不超过5MB">
                        {
                            imgList.map((img, index) =>
                                <Cell before={<Image size={96} src={img.data}/>}
                                      after={<Button mode="filled" size="s"
                                                     onClick={() => onDeleteImgBtnClick(index)}
                                      > 移除 </Button>}
                                      multiline
                                      style={{minHeight: 112}}
                                      description={img.size.toFixed(2) + "MB"}
                                > {img.name}
                                </Cell>
                            )
                        }
                        <Cell>
                            <FileInput accept="image/jpeg,image/jpg,image/png"
                                       onChange={e => onImgUpload(e)}
                                       label="点击上传图片"
                            />
                        </Cell>
                    </Section>
                    <Section header="您希望对该茶艺师投稿？" footer="茶艺师数据是本平台根本，请耐心审核！">
                        {
                            editType === 0 &&
                            <div>
                                <ButtonCell before={<FcUndo size={20}/>} onClick={e => {

                                }}>
                                    【垃圾投稿】驳回并删除该投稿
                                </ButtonCell>
                                <ButtonCell before={<FcCheckmark size={20}/>} onClick={e => {
                                    onSubmitBtnClick()
                                }}>
                                    【正确投稿】通过并奖励积分
                                </ButtonCell>
                            </div>
                        }
                        {
                            editType === 1 &&
                            <div>
                                <ButtonCell before={<FcCheckmark size={20}/>} onClick={e => {
                                    onSubmitBtnClick()
                                }}>
                                    【确认修改】立刻更新茶艺师信息
                                </ButtonCell>
                            </div>
                        }
                    </Section>
                </div>
            }
            {uploadingState === 1 &&
                <Section>
                    <Placeholder
                        header="正在上传中"
                        description="请稍后，请勿退出或切换应用..."
                    >
                        <Spinner size="l"/>
                    </Placeholder>
                </Section>
            }
            {uploadingState === 2 &&
                <Section>
                    <Placeholder
                        description={
                            <div style={{display: "flex", flexDirection: "column", rowGap: 20}}>
                                {onSubmitSuccessStr}
                                <Button
                                    before={<TbHomeDot size={24}/>}
                                    mode="filled"
                                    size="s"
                                    onClick={() => {
                                        navigate(-1)
                                    }}
                                >返回至上一层
                                </Button>
                            </div>
                        }
                        header="提交成功">
                        <FcOk size={64}/>
                    </Placeholder>
                </Section>
            }


            <InfoFooter/>
        </List>
    )
        ;
}

export default TeacherEdit;
