import React, {useEffect, useState} from 'react';
import {
    ButtonCell,
    Cell,
    FixedLayout,
    Headline,
    List, Modal, Placeholder,
    Section,
    SegmentedControl,

} from '@telegram-apps/telegram-ui';
import InfoFooter from "../component/InfoFooter";
import {useNavigate} from "react-router-dom";
import {
    SegmentedControlItem
} from '@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem';
import {TbClipboardCopy, TbFaceId, TbFaceIdError} from "react-icons/tb";
import {get_reports_api, valid_reports_api} from "../api/AdminAPI";
import toast from "react-hot-toast";
import {ImCrying} from "react-icons/im";
import {
    ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import copy from "copy-to-clipboard";
import {BiSolidUserDetail} from "react-icons/bi";
import WebApp from "@twa-dev/sdk";

function ReportManagementPage() {
    const navigate = useNavigate()
    const [segmentState, setSegmentState] = useState(-1)
    const [reportList, setReportList] = useState([] as ResponseReportEntity[])
    const [currentReport, setCurrentReport] = useState({} as ResponseReportEntity)
    const [unverifiedModalVisible, setUnverifiedModalVisible] = useState(false)

    useEffect(() => {
        loadReportList(segmentState)
    }, [])
    const onSegmentChange = (type: number) => {
        setSegmentState(type)
        loadReportList(type)
    }
    const loadReportList = (type: number) => {
        get_reports_api(type)
            .then((res) => {
                setReportList(res.data.data)
            })
    }
    const onUnverifiedItemClick = (item: ResponseReportEntity) => {
        setCurrentReport(item)
        setUnverifiedModalVisible(true)
    }
    const unverifiedModalVisibleChange = (visible: boolean) => {
        setUnverifiedModalVisible(visible)
    }
    const onToValidBtnClick = (error_id: number, type: number) => {
        valid_reports_api(error_id, type).then((res) => {
            setUnverifiedModalVisible(false)
            loadReportList(segmentState)
            toast.success(res.data.message)
        })
    }
    const onToTeacherDetailBtnClick = (teacher_id: string) => {
        setUnverifiedModalVisible(false)
        navigate("/TeacherDetail", {
            state: {
                teacher_id: teacher_id,
            }
        })
    }
    return (
        <div>
            <List>
                <Section header="筛选">
                    <Cell>
                        <SegmentedControl>
                            <SegmentedControlItem
                                onClick={() => {
                                    onSegmentChange(-1)
                                }}
                                selected={segmentState === -1}
                            >
                                未验证
                            </SegmentedControlItem>
                            <SegmentedControlItem
                                onClick={() => {
                                    onSegmentChange(1)
                                }}
                                selected={segmentState === 1}
                            >
                                已验证
                            </SegmentedControlItem>
                        </SegmentedControl>
                    </Cell>
                </Section>
                <Section footer="⚠警告：验证不通过的错误申请，将被永久移出，请管理员确认后再点击！">
                    {
                        reportList.length === 0 &&
                        <Section header={"本区暂无数据"}>
                            <Placeholder description="本类型下暂无相关数据...">
                                <ImCrying size={48}/>
                            </Placeholder>
                        </Section>
                    }
                    {
                        reportList.map(item =>
                            <Cell description={item.content}
                                  subtitle={"报告时间：" + item.report_time}
                                  multiline
                                  onClick={() => {
                                      onUnverifiedItemClick(item)
                                  }}
                            >
                            </Cell>
                        )
                    }
                </Section>
                <InfoFooter/>
            </List>
            <Modal open={unverifiedModalVisible}
                   dismissible={true}
                   onOpenChange={(open) => {
                       unverifiedModalVisibleChange(open)
                   }}
                   header={<ModalHeader></ModalHeader>}
            >
                <List style={{paddingBottom: '30px', background: 'var(--tgui--secondary_bg_color)'}}>
                    <Section header="请选择您要对该条错误的操作">
                        <ButtonCell before={<BiSolidUserDetail size={24}/>}
                                    onClick={() => {
                                        onToTeacherDetailBtnClick(currentReport.teacher_id)
                                    }}
                        >
                            【查看】前往茶艺师详情
                        </ButtonCell>
                        {
                            segmentState === -1 &&
                            <div>
                                <ButtonCell before={<TbFaceId size={24}/>}
                                            onClick={() => {
                                                WebApp.showConfirm("确定该报告合理吗？（确认后将显示在茶艺师详情页）", (r) => {
                                                    if (r === true) {
                                                        onToValidBtnClick(currentReport.id, 1)
                                                    }
                                                })
                                            }}
                                >
                                    【通过】该条报告合理（发奖励）
                                </ButtonCell>
                                <ButtonCell before={<TbFaceIdError size={24}/>}
                                            onClick={() => {
                                                WebApp.showConfirm("确认该报告为存在不合理、虚假等情况吗？", (r) => {
                                                    if (r === true) {
                                                        onToValidBtnClick(currentReport.id, 0)
                                                    }
                                                })
                                            }}
                                >
                                    【驳回】该条报告虚假
                                </ButtonCell>
                            </div>
                        }
                        {
                            segmentState === 1 &&
                            <ButtonCell before={<TbFaceIdError size={24}/>}
                                        onClick={() => {
                                            WebApp.showConfirm("确认永久删除本条体验报告吗？", (r) => {
                                                if (r === true) {
                                                    onToValidBtnClick(currentReport.id, 0)
                                                }
                                            })
                                        }}
                            >
                                【删除】该条报告虚假
                            </ButtonCell>
                        }
                        <ButtonCell before={<TbClipboardCopy size={24}/>}
                                    onClick={() => {
                                        copy(currentReport.teacher_id)
                                        toast.success("已复制茶艺师ID：\n" + currentReport.teacher_id)

                                    }}>
                            【复制】茶艺师ID
                        </ButtonCell>
                        <ButtonCell before={<TbClipboardCopy size={24}/>}
                                    onClick={() => {
                                        copy("" + currentReport.user_id)
                                        toast.success("已复制提交用户ID：\n" + currentReport.user_id)

                                    }}>
                            【复制】提交人ID
                        </ButtonCell>
                    </Section>
                </List>
            </Modal>
        </div>
    );
}


export default ReportManagementPage;
