import React from 'react';
import {Headline} from '@telegram-apps/telegram-ui';
import {FaTelegram} from "react-icons/fa";

function InfoFooter() {
    return (
        <div>
            <Headline weight="3" style={{
                textAlign: "center",
                display: "block",
                fontSize: 12,
                color: "var(--tgui--subtitle_text_color)"
            }}>
                Copyright © 2024 CoinGet·品茗. All rights reserved.
            </Headline>
            <Headline weight="3" style={{
                textAlign: "center",
                display: "block",
                fontSize: 12,
                color: "var(--tgui--subtitle_text_color)"
            }}>
                本小程序由 <FaTelegram size={10}/> Telegram 官方提供技术支持
            </Headline>
        </div>
    );
}

export default InfoFooter;
