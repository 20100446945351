import request from '../utils/request';

export function get_province_list_api() {
    return request<ResponseResult<ResponseAreaNodeEntity[]>>({
        url: '/area/getProvinceList',
        method: 'post',
    });
}

export function get_city_list_api(data: RequestAreaEntity) {
    return request<ResponseResult<ResponseAreaNodeEntity[]>>({
        url: '/area/getCityList',
        method: 'post',
        data
    });
}
export function get_district_list_api(data: RequestAreaEntity) {
    return request<ResponseResult<ResponseAreaNodeEntity[]>>({
        url: '/area/getDistrictList',
        method: 'post',
        data
    });
}

export function get_parent_id_api(data: RequestAreaEntity) {
    return request<ResponseResult<number>>({
        url: '/area/getParentId',
        method: 'post',
        data
    });
}



