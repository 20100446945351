import React, {useState} from 'react';
import {
    List,
    Section,
    Input,
    Button
} from '@telegram-apps/telegram-ui';
import InfoFooter from "../component/InfoFooter";
import {
    release_user_api, reset_user_modified_time_api, reset_user_score_api, reset_user_unlock_api, set_user_by_id_api
} from "../api/AdminAPI";
import toast from "react-hot-toast";
import WebApp from "@twa-dev/sdk";


function Miscellaneous() {
    const [inputUserId, setInputUserId] = useState(0)
    return (
        <List>
            <Section header="用户杂项操作">
                <Input header="输入操作对象用户ID" placeholder="输入操作对象用户ID"
                       value={inputUserId === 0 ? "" : inputUserId}
                       type={"number"}
                       onChange={e => setInputUserId(Number(e.target.value))}/>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px"}}>
                    <Button style={{width: "80%"}} mode="filled" size="m"
                            onClick={e => {
                                WebApp.HapticFeedback.impactOccurred("soft")
                                WebApp.showConfirm("确定解锁[" + inputUserId + "]用户吗？\n请仔细核对用户ID！", (r) => {
                                    if (r) {
                                        release_user_api(inputUserId)
                                            .then(res => {
                                                toast.success(res.data.message)
                                                setInputUserId(0)
                                            })
                                    } else {
                                        return;
                                    }
                                })
                            }}
                    >解除封禁</Button>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px"}}>
                    <Button style={{width: "80%"}} mode="filled" size="m"
                            onClick={e => {
                                WebApp.HapticFeedback.impactOccurred("soft")
                                WebApp.showConfirm("确定锁定[" + inputUserId + "]用户吗？\n请仔细核对用户ID！", (r) => {
                                    if (r) {
                                        set_user_by_id_api(inputUserId, 3, 1)
                                            .then(res => {
                                                toast.success(res.data.message)
                                                setInputUserId(0)
                                            })
                                    } else {
                                        return;
                                    }
                                })
                            }}
                    >封禁</Button>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px"}}>
                    <Button style={{width: "80%"}} mode="filled" size="m"
                            onClick={e => {
                                WebApp.HapticFeedback.impactOccurred("soft")
                                WebApp.showConfirm("确定移除[" + inputUserId + "]的会员权限吗？\n请仔细核对用户ID！", (r) => {
                                    if (r) {
                                        set_user_by_id_api(inputUserId, 2, -1)
                                            .then(res => {
                                                toast.success(res.data.message)
                                                setInputUserId(0)
                                            })
                                    } else {
                                        return;
                                    }
                                })
                            }}
                    >移除会员权限</Button>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px"}}>
                    <Button style={{width: "80%"}} mode="filled" size="m"
                            onClick={e => {
                                WebApp.HapticFeedback.impactOccurred("soft")
                                WebApp.showConfirm("确定重置[" + inputUserId + "]用户的地区修改时间吗？\n请仔细核对用户ID！", (r) => {
                                    if (r) {
                                        reset_user_modified_time_api(inputUserId)
                                            .then(res => {
                                                toast.success(res.data.message)
                                                setInputUserId(0)
                                            })
                                    } else {
                                        return;
                                    }
                                })
                            }}
                    >重置用户修改地区时间</Button>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px"}}>
                    <Button style={{width: "80%"}} mode="filled" size="m"
                            onClick={e => {
                                WebApp.HapticFeedback.impactOccurred("soft")
                                WebApp.showConfirm("确定清空[" + inputUserId + "]用户的积分吗？\n请仔细核对用户ID！", (r) => {
                                    if (r) {
                                        reset_user_score_api(inputUserId)
                                            .then(res => {
                                                toast.success(res.data.message)
                                                setInputUserId(0)
                                            })
                                    } else {
                                        return;
                                    }
                                })
                            }}
                    >清空用户积分</Button>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px"}}>
                    <Button style={{width: "80%"}} mode="filled" size="m"
                            onClick={e => {
                                WebApp.HapticFeedback.impactOccurred("soft")
                                WebApp.showConfirm("确定重置[" + inputUserId + "]用户的解锁清单吗？\n请仔细核对用户ID！", (r) => {
                                    if (r) {
                                        reset_user_unlock_api(inputUserId)
                                            .then(res => {
                                                toast.success(res.data.message)
                                                setInputUserId(0)
                                            })
                                    } else {
                                        return;
                                    }
                                })
                            }}
                    >重置用户解锁清单</Button>
                </div>
            </Section>
            <InfoFooter/>
        </List>
    );
}

export default Miscellaneous;
