import React, {useEffect, useState} from 'react';
import {List, Section, Cell, Avatar, Badge, Placeholder, Spinner} from '@telegram-apps/telegram-ui';
import {FcInspection} from "react-icons/fc";
import InfoFooter from "../component/InfoFooter";
import {useLocation, useNavigate,} from "react-router-dom";
import {get_teacher_list_by_district_api} from "../api/UserAPI";
import {ImCrying} from "react-icons/im";
import WebApp from "@twa-dev/sdk";

function TeacherSelector() {
    const navigate = useNavigate()
    const {state} = useLocation();
    const [teacherItemListMap, setTeacherItemListMap] = useState({} as Map<string, ResponseTeacherItemEntity[]>)
    const [districtScore, setDistrictScore] = useState("0")
    const [districtTeacherCount, setDistrictTeacherCount] = useState(0)
    useEffect(() => {
        get_teacher_list_by_district_api({request_area_id: state.district_id} as RequestAreaEntity)
            .then((res) => {
                const map = new Map<string, ResponseTeacherItemEntity[]>()
                let score = 0
                let existScoreTeacherCount = 0
                setDistrictTeacherCount(res.data.data.length)
                for (const item of res.data.data) {
                    const townList = map.get(item.town)
                    if (item.score !== 0) {
                        score += item.score
                        existScoreTeacherCount += 1
                    }
                    if (townList == null) {
                        map.set(item.town, [item])
                    } else {
                        townList.push(item)
                    }
                }
                setDistrictScore((score / existScoreTeacherCount).toFixed(1))
                setTeacherItemListMap(map)
            })
    }, [])
    const onToTeacherDetailBtnClick = (teacher_id: string) => {
        WebApp.HapticFeedback.impactOccurred("soft")
        navigate("/TeacherDetail", {
            state: {
                teacher_id: teacher_id,
            }
        })
    }
    return (
        <List style={{minHeight: "100%"}}>
            <Section header={"🎃品茗·区域信息"}>
                <Cell before={<FcInspection size={"32"}/>}
                      description={"已收录茶艺师：" + districtTeacherCount + "位"}
                      subtitle={isNaN(Number(districtScore)) ? "暂无评分" : "推荐分数：" + districtScore + "分"}>
                    {state.city_name}·{state.district_name}
                </Cell>
            </Section>

            {
                teacherItemListMap.size === undefined &&
                <Section header={"精彩加载中，请稍等片刻..."}>
                    <Placeholder description="正在加载茶艺师列表...">
                        <Spinner size="s"/>
                    </Placeholder>
                </Section>
            }
            {
                teacherItemListMap.size !== undefined && teacherItemListMap.size <= 0 &&
                <Section header={"本区暂无数据"}>
                    <Placeholder description="非常抱歉，目前尚未收录本地区茶艺师信息！暂时请去别处看看吧！">
                        <ImCrying size={48}/>
                    </Placeholder>
                </Section>
            }
            {
                teacherItemListMap.size !== undefined && teacherItemListMap.size > 0 &&
                Array.from(teacherItemListMap).map(kvItem =>
                    <Section header={kvItem[0]}>
                        {
                            kvItem[1].map(teacherItem =>
                                <Cell
                                    before={<Avatar size={48}
                                                    src={process.env.REACT_APP_BASE_URL + "/teacherThumbnailImage?teacherId=" + teacherItem.id}/>}
                                    description={"服务价格：" + teacherItem.price}
                                    after={teacherItem.score != 0 &&
                                        <Badge type="number">{teacherItem.score.toFixed(1)}</Badge>}
                                    onClick={() => {
                                        onToTeacherDetailBtnClick(teacherItem.id)
                                    }}
                                >
                                    {teacherItem.name}
                                </Cell>
                            )
                        }
                    </Section>
                )
            }
            <InfoFooter/>
        </List>
    );
}

export default TeacherSelector;
