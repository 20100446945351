import {
    Cell,
    List,
    Section,
    ButtonCell, Placeholder
} from '@telegram-apps/telegram-ui';
import {
    FcCallback,
    FcPuzzle,
    FcMms,
    FcPositiveDynamic,
    FcUpload,
    FcTreeStructure,
    FcRadarPlot,
    FcConferenceCall,
    FcDisclaimer,
    FcGenealogy,
    FcPaid,
    FcDonate,
    FcExpired,
    FcDebt, FcUnlock, FcAssistant, FcMindMap, FcCollaboration, FcDisplay, FcIdea
} from "react-icons/fc";
import InfoFooter from "../component/InfoFooter";
import {useLocation} from "react-router-dom";
import {get_my_customer_url, get_my_payment, get_super_customer_url} from "../api/UserAPI";
import WebApp from "@twa-dev/sdk";
import {Fa1, Fa2, Fa3} from "react-icons/fa6";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import {useEffect, useState} from "react";

function Service() {
    const {state} = useLocation();
    const [payment, setPayment] = useState("")
    useEffect(() => {
        get_my_payment()
            .then(res => {
                setPayment(res.data.data)
            })
    }, [])
    return (
        <List>
            {
                state.type !== 4 &&
                <Placeholder
                    description="提升您的生活品质"
                    header="🤝🏻合作及客户服务">
                </Placeholder>
            }
            {
                state.type === 1 &&
                <div>
                    <Section header="💰茶室入驻优势">
                        <Cell Component="label"
                              before={<FcPuzzle size={"32"}/>}
                              multiline
                              description="用户基础庞大，涵盖各种消费群体，且用户定位精准，提升您的盈利转化。"
                              style={{whiteSpace: "pre-wrap"}}
                        >用户分类精准</Cell>
                        <Cell Component="label"
                              before={<FcMms size={"32"}/>}
                              multiline
                              description="多图轮播，完美展示，吸引眼球，提升用户体验，用户易用性与互动性高。"
                              style={{whiteSpace: "pre-wrap"}}
                        >信息展示全面</Cell>
                        <Cell Component="label"
                              before={<FcConferenceCall size={"32"}/>}
                              multiline
                              description="软件由专人定制，精准满足需求，提升功能灵活性，助力业务创新与发展。"
                              style={{whiteSpace: "pre-wrap"}}
                        >专属定制功能</Cell>
                        <Cell Component="label"
                              before={<FcDisclaimer size={"32"}/>}
                              multiline
                              description="茶室入驻均有基础门槛，防止同行通过消息覆盖、信息迷惑等方式恶意竞争。"
                              style={{whiteSpace: "pre-wrap"}}
                        >杜绝恶意竞争</Cell>
                        <Cell Component="label"
                              before={<FcGenealogy size={"32"}/>}
                              multiline
                              description="您有资源，我有渠道，合作共赢，为客户提供优质服务，才能赢得客户的口碑。"
                              style={{whiteSpace: "pre-wrap"}}
                        >全面合作共赢</Cell>

                        <ButtonCell before={<FcCallback size={"20"}/>}
                                    onClick={e => {
                                        get_my_customer_url().then(res => WebApp.openTelegramLink(res.data.data))
                                    }}>
                            【点此咨询客服】 了解更多
                        </ButtonCell>

                    </Section>
                </div>
            }
            {
                state.type === 2 &&
                <Section header="💃茶艺师入驻优势" footer="为保证服务质量，本平台目前不接受任何代聊客服申请，请理解！如发现代聊违规注册，将封禁代聊与攥写报告的所有用户！">
                    <Cell Component="label"
                          before={<FcMms size={"32"}/>}
                          multiline
                          description="茶艺师可自主展示专业技能与特色服务，提升个人形象，吸引更多客户关注和预约。"
                          style={{whiteSpace: "pre-wrap"}}
                    >信息速递</Cell>
                    <Cell Component="label"
                          before={<FcPositiveDynamic size={"32"}/>}
                          multiline
                          description="成为特约茶艺师，获得平台的专属推荐，展示您的特长，获取更优质的客户。"
                          style={{whiteSpace: "pre-wrap"}}
                    >展示特长</Cell>
                    <Cell Component="label"
                          before={<FcUpload size={"32"}/>}
                          multiline
                          description="优秀茶艺师有机会成为推荐置顶，增加曝光率，吸引更多客户，实现更高收益。"
                          style={{whiteSpace: "pre-wrap"}}
                    >推荐置顶</Cell>
                    <Cell Component="label"
                          before={<FcTreeStructure size={"32"}/>}
                          multiline
                          description="茶艺师可自定义用户筛选条件，精准匹配目标客户，提升服务效率与满意度。"
                          style={{whiteSpace: "pre-wrap"}}
                    >筛选用户</Cell>
                    <Cell Component="label"
                          before={<FcRadarPlot size={"32"}/>}
                          multiline
                          description="提供与其他茶艺师、茶室合作机会，扩大业务网络，增强市场竞争力，互利共赢。"
                          style={{whiteSpace: "pre-wrap"}}
                    >结盟发展</Cell>
                    <ButtonCell before={<FcCallback size={"20"}/>} onClick={e => {
                        get_my_customer_url().then(res => WebApp.openTelegramLink(res.data.data))
                    }}>
                        【点此咨询客服】 了解更多
                    </ButtonCell>
                </Section>
            }
            {
                state.type === 3 &&
                <div>
                    <Section header="✏️用户指南"
                             footer="【专属客服】我们将根据您的习惯分配专属客服解答您的问题！在此，再次感谢您对品茗平台的大力支持，我们将持续为您提供高质量的服务！">
                        <Cell Component="label"
                              before={<FcPaid size={"32"}/>}
                              multiline
                              description="联系客服，获取我们的《专属教程》，手把手教您使用币安，方便您畅游无阻。"
                        >Binance注册、付款问题</Cell>
                        <Cell Component="label"
                              before={<FcDonate size={"32"}/>}
                              multiline
                              description="签到积分未及时更新、错误计算消费积分、系统故障导致余额异常等问题。"
                        >积分余额、签到未奖励问题</Cell>
                        <Cell Component="label"
                              before={<FcExpired size={"32"}/>}
                              multiline
                              description="会员到期时间错误、会员未生效、会员无法解锁茶艺师、会员延期等问题。"
                        >会员开通、续期问题</Cell>
                        <Cell Component="label"
                              before={<FcDebt size={"32"}/>}
                              multiline
                              description="申请成为地区管理员，开通新地区、平台协作、功能建议等问题。"
                        >地区管理、协作问题</Cell>
                        <ButtonCell before={<FcCallback size={"20"}/>} onClick={e => {
                            get_my_customer_url().then(res => WebApp.openTelegramLink(res.data.data))
                        }}>
                            【点此咨询客服】 解答疑问
                        </ButtonCell>
                    </Section>
                    <Section header="✏️客服投诉" footer={"客服主管在线时间：每日 9:00-21:00"}>
                        <Cell Component="label"
                              before={<FcCollaboration size={"32"}/>}
                              multiline
                              description="如有：客服不回复、服务质量问题、修改显示区域等问题，可联系客服主管解决"
                        >客服主管</Cell>
                        <ButtonCell before={<FcCallback size={"20"}/>} onClick={e => {
                            get_super_customer_url().then(res => WebApp.openTelegramLink(res.data.data))
                        }}>
                            【联系客服主管】其他问题
                        </ButtonCell>
                    </Section>
                </div>
            }
            {
                state.type === 4 &&
                <div>
                    <Placeholder header="会员开通指南"
                                 description="畅享品茗，高效便捷">
                    </Placeholder>
                    <Section header="会员介绍">
                        <Cell Component="label"
                              multiline
                              before={<FcUnlock size={"32"}/>}
                              description="成为会员，便不再需要通过使用积分解锁茶艺师信息，玩转品茗。"
                        >茶艺师信息全解锁</Cell>
                        <Cell Component="label"
                              multiline
                              before={<FcMindMap size={"32"}/>}
                              description="不管您出差或旅游，一键切换显示区域，会员解锁不受限制。"
                        >全国各地信息收率</Cell>
                        <Cell Component="label"
                              multiline
                              before={<FcAssistant size={"32"}/>}
                              description="会员如有疑问，平台有会员专属客服7*24小时为您解答相关问题。"
                        >专属客服贴心服务</Cell>
                        <Cell Component="label"
                              multiline
                              before={<FcPaid size={"32"}/>}
                              description="我们推出了多种不同的会员时长供您选择，您可以根据您的需求按需选择。"
                        >价格便宜性价比高</Cell>
                    </Section>
                    <Section header="会员定价"
                             footer="推荐使用【USDT】支付，其他付款方式请咨询客服">
                        <Cell Component="label"
                              before={<Fa1 size={"28"}/>}
                              subhead="月度会员"
                              description="适合【临时出差】、【偶尔尝鲜】人群"
                        >10 USDT / 30天</Cell>
                        <Cell Component="label"
                              before={<Fa2 size={"28"}/>}
                              subhead="季度会员"
                              description="适合【长期旅居】、【寻求新鲜】人群"
                        >20 USDT / 90天</Cell>
                        <Cell Component="label"
                              before={<Fa3 size={"28"}/>}
                              subhead="永久会员"
                              description="适合【威猛男性】、【长期居住】人群"
                        >50 USDT / 永久</Cell>

                        <ButtonCell before={<FcCallback size={"20"}/>} onClick={e => {
                            get_my_customer_url().then(res => WebApp.openTelegramLink(res.data.data))
                        }}>
                            【咨询客服】 立刻开通
                        </ButtonCell>
                    </Section>
                    <Section header="⚠谨防诈骗提示">
                        <Cell Component="label"
                              before={<FcIdea size={"28"}/>}
                              description="本平台从成立至今，接到多位用户反馈在其他盗版网站上充值被骗的经历。为防止各位用户上当受骗，请付款时按下方的专属付款码付款。"
                              multiline
                        >专属付款码</Cell>
                        <ButtonCell before={<FcDisplay size={"20"}/>} onClick={e => {
                            toast.success("已复制：" + payment)
                            copy(payment)
                        }}>
                            【专属付款码】点击即可复制
                        </ButtonCell>
                    </Section>
                </div>
            }
            <InfoFooter/>
        </List>
    );
}

export default Service;
