import React, {useEffect, useState} from 'react';
import {PinInput} from "@telegram-apps/telegram-ui";
import {shutdown_api} from "../api/AdminAPI";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";


function PinPage() {
    const [pin, setPin] = useState([] as number[])
    const navigate = useNavigate()
    useEffect(() => {
    }, [])

    return (
        <div style={{width: "100%", height: "100%"}}>
            <PinInput
                autoFocus={false}
                style={{width: "100%", height: "100%"}}
                pinCount={6}
                value={pin}
                label={"请输入Pin码"}
                onChange={value => {
                    setPin(value)
                    if (value.length === 6) {
                        let pwd = ""
                        for (let i = 0; i < value.length; i++) {
                            let valueElement = value[i];
                            pwd = pwd + valueElement
                        }
                        shutdown_api(pwd)
                            .then(res => {
                                toast.success(res.data.message)
                                navigate("/")
                            })
                    }
                }}
            />
        </div>
    );
}

export default PinPage;
