import request from '../utils/request';

export function login_api(data: RequestAuthEntity) {
    return request<ResponseResult<ResponseUserEntity>>({
        url: '/user/login',
        method: 'post',
        data
    });
}

export function sign_api() {
    return request<ResponseResult>({
        url: '/user/sign',
        method: 'post',
    });
}

export function is_admin_api() {
    return request<ResponseResult<ResponseAdminEntity>>({
        url: '/user/isAdmin',
        method: 'post',
    });
}

export function update_user_city_id(data: RequestAreaEntity) {
    return request<ResponseResult>({
        url: '/user/updateUserCityId',
        method: 'post',
        data
    });
}

export function get_district_teacher_list_api() {
    return request<ResponseResult<ResponseFunctionListEntity<ResponseDistrictItemEntity>>>({
        url: '/user/getDistrictTeacherList',
        method: 'post',
    });
}

export function get_teacher_list_by_district_api(data: RequestAreaEntity) {
    return request<ResponseResult<ResponseTeacherItemEntity[]>>({
        url: '/user/getTeacherListByDistrict',
        method: 'post',
        data
    });
}

export function get_teacher_detail_api(data: RequestTeacherEntityById) {
    return request<ResponseResult<ResponseTeacherConvergeEntity>>({
        url: '/user/getTeacherDetail',
        method: 'post',
        data
    });
}

export function submit_error_api(data: RequestErrorEntity) {
    return request<ResponseResult>({
        url: '/user/submitError',
        method: 'post',
        data
    });
}

export function submit_report_api(data: RequestReportEntity) {
    return request<ResponseResult>({
        url: '/user/submitReport',
        method: 'post',
        data
    });
}

export function submit_teacher_api(data: RequestNewTeacherEntity) {
    return request<ResponseResult>({
        url: '/user/submitTeacher',
        method: 'post',
        data
    });
}

export function unlock_teacher_api(teacher_id: string) {
    return request<ResponseResult<ResponseTeacherConvergeEntity>>({
        url: '/user/unlockTeacher?teacherId=' + teacher_id,
        method: 'post',
    });
}

export function get_self_info_api() {
    return request<ResponseResult<ResponseUserEntity>>({
        url: '/user/getSelfInfo',
        method: 'post',
    });
}

export function get_outstanding_list_api() {
    return request<ResponseResult<ResponseTeacherEntity[]>>({
        url: '/user/getOutstandingList',
        method: 'post',
    });
}

export function get_my_customer_url() {
    return request<ResponseResult<string>>({
        url: '/user/getMyCustomerUrl',
        method: 'post',
    });
}
export function get_super_customer_url() {
    return request<ResponseResult<string>>({
        url: '/user/getSuperCustomerUrl',
        method: 'post',
    });
}
export function get_my_payment() {
    return request<ResponseResult<string>>({
        url: '/user/getMyPayment',
        method: 'post',
    });
}
